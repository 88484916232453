<template>
    <div class="shopping">
        <van-sticky>
            <van-nav-bar :title="'购物车('+shopList.length+')'"/>
        </van-sticky>
        <div class="shopping-list">
            <van-swipe-cell v-for="(item, i) in shopList" :key="i">
               <van-card
                    :price="item.price"
                    desc="描述信息"
                    title="商品标题"
                    :thumb="imgUrl1"
                    class="my-card"
                    >
                    <template #tag>
                        <van-checkbox v-model="item.checked" @change="selectOne"></van-checkbox>
                    </template>
                    <template #tags>
                        <van-tag plain type="danger">标签</van-tag>
                        <van-tag plain type="danger">标签</van-tag>
                    </template>
                    <template #num>
                       <van-stepper v-model="item.count" step="1"/>
                    </template>
                </van-card>
                <template #right>
                    <van-button square text="删除" type="danger" @click="deleteGoods(i)" class="delete-button" />
                </template>
            </van-swipe-cell> 
        </div>
        <van-submit-bar :price="total" button-text="结算" @submit="onSubmit">
            <van-checkbox v-model="checkedAll" @click="selectAll">全选</van-checkbox>
        </van-submit-bar>
        <no-data v-if="shopList.length === 0"/>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value: 1,
            checkedAll: false,
            imgUrl1: require('@/assets/img/horse1.jpg'),
            shopList: [
                {price: '123.10', checked: false, count: 1,},
                {price: '12.00', checked: false, count: 1,},
                {price: '143.01', checked: false, count: 2,},
                {price: '13.20', checked: false, count: 3,},
                {price: '1223.00', checked: false, count: 4,},
                {price: '1243.00', checked: false, count: 2,},
                {price: '23.00', checked: false, count: 1,},
            ],
        }
    },
    computed: {
        total() {
            let total = 0
            this.shopList.map(ele=>{
                if(ele.checked){
                    total += (ele.price-0)*(ele.count-0)
                }
            })
            return total*100
        }
    },
    methods: {
        selectAll() {
            this.shopList.map(ele=>{
                this.$set(ele, 'checked', this.checkedAll)
            })
        },
        selectOne() {
            //debugger
            let arr = []
            arr = this.shopList.filter(ele=>{
                return ele.checked
            })
            if(arr.length === this.shopList.length){
                this.checkedAll = true
            }else{
                this.checkedAll = false
            }
        },
        deleteGoods(i) {
             this.$help.confirm({
                title: '提示',
                message: '确定删除该商品吗',
            })
            .then(() => {
                this.shopList.splice(i, 1)
            })
            .catch(() => {})
        },
        onSubmit() {
            let arr = []
            arr = this.shopList.filter(ele=>{
                return ele.checked
            })
            if(arr.length !== 0) {
                this.$help.storeTemp.set('order', JSON.stringify(arr))
                this.$router.push('/ordersubmit')
            }
        }
    }

}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .shopping{
        &-list{ 
            margin: 20px 10px 10px 10px;
            .van-swipe-cell{
               box-shadow: 0 0 9px 6px $boxShadow;
               border-radius: 9px;
               margin-bottom: 15px;
            }
            padding-bottom: 50px;
        }
        .my-card{
            .van-card__header{
                margin-left: 38px;
                .van-card__tag{
                    top: 50%;
                    transform: translateY(-50%);
                    left: -38px;
                    .van-checkbox__icon--checked .van-icon{
                            color: #fff;
                            background-color: $main;
                            border-color: $main;
                    }
                }
            }
            
        }
        .van-swipe-cell__right{
            .delete-button{
                height: 100%;
            }
        }
        .van-submit-bar__bar{
            .van-checkbox__icon--checked .van-icon{
                color: #fff;
                background-color: $main;
                border-color: $main;
            }
        }
    }
</style>